.find-lawyer-container {
  display: flex;
  flex-wrap: nowrap;
}

.lawyer-card a {
  text-decoration: none;
  color: unset;
}

.lawyer-card .card-image {
  background-color: rgb(253, 237, 217);
}
.lawyer-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px;
}
.legal-professionals-box {
  max-height: 87vh;
  overflow-y: auto;
}

.legal-professionals-box::-webkit-scrollbar {
  width: 14px;
}

.legal-professionals-box::-webkit-scrollbar-thumb {
  background: #d28b13;
  border-radius: 50px;
}

.legal-professionals-box::-webkit-scrollbar-thumb:hover {
  background: #c27f0b;
}

.legal-professionals-box::-webkit-scrollbar-thumb:active {
  background: #c27f0b;
}

.aside-container .aside-toggler {
  right: 15px;
}

.find-lawyer-container > div {
  width: 100%;
}

.find-lawyer-container .aside-container {
  max-width: 350px;
}

.find-lawyer-container .find-lawyer-main {
  max-width: calc(100vw - 350px);
}

.drawer-icon,
.aside-overlay {
  display: none;
}

.find-lawyer-main .container-fluid {
  max-width: 1500px;
}

.find-lawyer-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.find-lawyer-grid .lawyer-card {
  width: 100%;
  max-width: 22.22%;
}

.clear-filter {
  font-size: 14px;
  color: var(--darker-grey);
}

@media screen and (max-width: 1900px) {
  .find-lawyer-grid {
    gap: 2.5rem;
  }
  .find-lawyer-grid .lawyer-card {
    max-width: 22%;
  }
}

@media screen and (max-width: 1440px) {
  .find-lawyer-grid {
    gap: 2rem;
  }
  .find-lawyer-grid .lawyer-card {
    max-width: 22.6%;
  }
}

@media screen and (max-width: 1366px) {
  .find-lawyer-grid {
    gap: 2.5rem;
  }
  .find-lawyer-grid .lawyer-card {
    max-width: 30%;
  }
}

@media screen and (max-width: 900px) {
  .find-lawyer-grid .lawyer-card {
    max-width: 47%;
  }
}

@media screen and (max-width: 767px) {
  .find-lawyer-grid {
    gap: 2rem;
  }
  .find-lawyer-grid .lawyer-card {
    max-width: 46%;
  }
}

@media screen and (max-width: 576px) {
  .find-lawyer-grid {
    justify-content: center;
  }
  .find-lawyer-grid .lawyer-card {
    max-width: 300px;
  }
}

@media screen and (max-width: 1440px) {
  .find-lawyer-container .aside-container {
    max-width: 260px;
  }

  .find-lawyer-container .find-lawyer-main {
    max-width: calc(100vw - 260px);
  }
}

@media screen and (max-width: 1200px) {
  .drawer-icon,
  .aside-overlay {
    display: unset;
  }

  .find-lawyer-container .aside-container {
    max-width: 340px;
  }

  .find-lawyer-container .find-lawyer-main {
    max-width: 100vw;
  }
}
