/* Footer */
.footermainestdiv {
  background-image: url(../../assets/images/lawyer-transparent-logo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  /* background-position-x: -280px; */
  background-color: #222222;
  /* margin-top: 20px; */
}

.footermaininnerdiv {
  /* width: 85%; */
  padding-top: 50px;
  /* background-color: orangered; */
  margin: 0 auto;
}

.footerfirstdiv {
  /* background-color: #D88531; */
  width: 90%;
  margin: 0 auto;
  margin-top: 40px;
}

.topboxone {
  /* width: 100%; */
  /* background-color: green; */
  border-left: 5px solid #474747;
  /* padding: 20px; */
  padding: 20px 10px;
}

.topboxoneimgs {
  /* margin: 10px; */
  display: block;
}

.numberptextno {
  margin-top: 5px;
  /* margin-bottom: 10px ; */
  color: white;
  font-size: 20px;
}

.numberptext {
  color: white;
  font-size: 20px;
}

.footerseconddiv {
  width: 100%;
  margin-top: 20px;
  padding-top: 40px;
  /* background-color: rgb(76, 76, 136); */
}

.footerlogoimgdiv {
  width: 180px;
  height: 180px;
  margin-top: 10px;
  margin-right: 10px;
  /* background-color: blue; */
}

.footerlogo {
  /* margin-top: 20px; */
  width: 180px;
}

.footerlastcentertext {
  color: #bfbfbf;
  /* background-color: #D88531; */
  padding: 10px;
  width: 100%;
  /* padding-right: 20px; */
  margin-top: 15px;
  font-size: 14px;
}

.spanheadingdash {
  content: " ";
  height: 3px;
  width: 80px;
  /* background-color: #474747; */
}

.footerheadinglast {
  width: 80%;
  display: inline;
  /* background-color: #D88531; */
  font-size: 18px;
  color: white;
}

.footerheadinglast::before {
  display: inline-block;
  content: " ";
  height: 2px;
  width: 20px;
  margin-left: -6px;
  margin-right: 12px;
  top: -5px;
  background-color: #474747;
  position: relative;
}

.openingdays {
  margin-top: 20px;
  font-size: 14px;
  color: #bfbfbf;
}

.openingtimingtext {
  font-size: 11px;
  width: auto;
  /* background-color: green; */
  line-height: 2px;
  margin-left: 10px;
  color: white;
}

.practicearealist {
  margin-top: 20px;
  /* margin-top: 20px; */
  color: #d5aa6d;
  font-size: 13px;
  list-style: none;
}

.practicearealist li {
  /* background-color: yellow; */
  line-height: 30px;
  font-weight: 200;
  margin-right: 40px;
  /* cursor: pointer; */
}

/* .practicearealist li:hover{
    font-weight: 500;
} */
.practicemore {
  margin-right: 40px;
  margin-left: 33px;
  display: block;
  /* background-color: aquamarine; */
  text-align: end;
  font-size: 13px;
  color: #d5aa6d;
  padding-right: 15px;
  margin-bottom: 10px;
  font-weight: bold;
}

.footerlastdivinputandbtn {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footerlastdivinput {
  background-color: #1c1c1c;
  color: #5d5d5d;
  padding: 10px;
  border: none;
  outline: none;
  width: 65%;
  font-size: 13px;
}

.footerlastdivbtn {
  background-color: #d5aa6d;
  color: white;
  padding: 10px;
  font-size: 13px;
  width: 35%;
  border: none;
  outline: none;
}

.hammerimgdiv {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* @media screen and (max-width: 1700px) {
    .hammerimgdiv {
        right: -220px;
    }
}

@media screen and (max-width: 1600px) {
    .hammerimgdiv {
        right: -200px;
    }
}

@media screen and (max-width: 1500px) {
    .hammerimgdiv {
        right: -160px;
    }
}

@media screen and (max-width: 1400px) {
    .hammerimgdiv {
        right: -150px;
    }
} */

.copyright {
  background-color: #353535;
}

@media screen and (max-width: 767px) {
  .hammerimgdiv {
    position: relative;
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .hammerimg {
    height: 100px;
  }
}
