.mantine-b1kpwj[data-selected] {
  background-color: #eaa954 !important;
}

.ajkfjdskf svg{
  width: 15px;
}

.ajkfjdskf{
  background: linear-gradient(45deg, #2e51b1, transparent);
  
}