.about-top-section {
  background: url(../../assets/images/about-us.jpg);
}

.Title-area {
  padding: 65px 0px 45px 100px;
  position: relative;
}

.Title-area h3 {
  font-size: 15px;
  line-height: normal;
  color: #474747;
  margin-bottom: 10px;
  font-weight: bold;
}

.Title-area h2 {
  font-size: 26px;
  margin-bottom: 22px;
  margin-top: 0;
  font-weight: bold;
}

.Title-area p {
  font-size: 13px;
  color: #9b9b9b;
}

.Title-area:before {
  position: absolute;
  content: "";
  top: 113px;
  left: 0;
  background: #d5aa6d;
  height: 2px;
  width: 70px;
}

.progress-left-img span {
  position: absolute;
  top: 0;
  right: 35px;
  display: inline-block;
  cursor: pointer;
}

.progress-left-img span svg {
  width: 65px;
  height: 65px;
  background: #d5aa6d;
  color: #fff;
  font-size: 28px;
  padding: 22px;
}

.history-right-column p {
  font-size: 17px;
  color: #9b9b9b;
  line-height: 25px;
  margin-bottom: 10px;
  top: -6px;
}

.skill {
  font-size: 13px;
  color: #222222;
  font-weight: bold;
  margin-bottom: 10px;
}

.progress_cont {
  width: 90%;
}

.progress_cont span {
  position: absolute;
  right: -40px;
  top: 19px;
}

.progressbar {
  padding: 0 10px;
}

.progressbar .progress {
  height: 4px;
  background-color: #eeeeee;
  margin-bottom: 30px;
}

.progressbar .progress-bar {
  background-color: #d5aa6d;
  transition: width 4s;
}

.asset-button {
  font-size: 13px;
  color: #333333 !important;
  font-weight: bold !important;
  padding: 17px 26px;
  border: 2px solid #eee !important;
  background: #fff !important;
  line-height: 1;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  -webkit-transition: all 300ms linear 0ms;
  -o-transition: all 300ms linear 0ms;
  -mz-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}

.asset-button:hover {
  color: #fff !important;
  background-color: #d5aa6d !important;
  border-color: #d5aa6d !important;
  -webkit-transition: all 300ms linear 0ms;
  -o-transition: all 300ms linear 0ms;
  -mz-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
  box-shadow: none;
}

.h-2-p-c-default {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -mz-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 40px;
}

.h-2-p-c-default .symbol svg {
  font-size: 35px;
  color: #ffffff;
  background: #282828;
  height: 60px;
  width: 60px;
  text-align: center;
  padding: 15px;
  margin-bottom: 20px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -mz-transition: all 0.5s;
  transition: all 0.5s;
}

.h-2-p-c-default h2 {
  position: relative;
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  padding-top: 10px;
}

.h-2-p-c-default a {
  color: #282828;
}

.h-2-p-c-default h2:before {
  position: absolute;
  content: "";
  top: -8px;
  left: 0;
  width: 20px;
  height: 2px;
  background: #282828;
}

.h-2-p-c-default p {
  line-height: 21px;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #9b9b9b;
}

.h-2-p-c-default:hover *:not(p) {
  color: #d5aa6d;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -mz-transition: all 0.5s;
  transition: all 0.5s;
}

.about-services .symbol svg * {
  fill: white !important;
}

.h-2-p-c-default:hover svg {
  background: #d5aa6d;
  color: #fff;
}
.h-2-p-c-default:hover a svg {
  background: transparent;
  color: #d5aa6d;
}

.fun-fact-area {
  background: url(../../assets/images/fun-fact-area-bg.png);
  background-size: cover;
  padding-bottom: 80px;
  position: relative;
  background-position: center;
  background-attachment: fixed;
}

.fun-fact-area:before {
  position: absolute;
  z-index: 0;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(40, 40, 40, 0.85);
}

.contact-title h3 {
  color: #e5e4e4;
}

.counter-container div {
  z-index: 3;
}

.counter-container .countdown-content {
  border: 1px #4c4b4b solid !important;
  position: relative;
  padding: 30px 30px 22px;
}

.counter-container .countdown-content:before {
  position: absolute;
  content: "";
  width: 19px;
  height: 3px;
  background: white;
  top: 20px;
  left: 30px;
}

.testimonials-section {
  background-color: #f9f9f9;
}

.owl-testimonial-content {
  padding: 0px 30px 34px;
  background-color: #fff;
  border-width: 0 1px 1px 1px;
  border-color: #f1f1f1;
  border-style: solid;
  -webkit-box-shadow: 0 2.3px 4px 0.1px #e8e8e8;
  -moz-box-shadow: 0 2.3px 4px 0.1px #e8e8e8;
  -ms-box-shadow: 0 2.3px 4px 0.1px #e8e8e8;
  box-shadow: 0 2.3px 4px 0.1px #e8e8e8;
  position: relative;
}

.owl-testimonial-content i {
  font-size: 17px;
  color: #ffffff;
  background: #d5aa6d;
  display: inline-block;
  padding: 7px 25px 3px;
}

.owl-testimonial-content h2 {
  font-size: 24px;
  color: #282828;
  font-weight: bold;
  font-family: "Lora", serif;
  line-height: 36px;
  margin-top: 13px;
}

.owl-testimonial-content h2 span {
  float: left;
  padding-right: 30px;
  margin-top: 3px;
}

.owl-testimonial-content p {
  font-size: 17px;
  color: #9b9b9b;
  font-family: "Raleway", sans-serif;
  line-height: 30px;
  margin-top: 25px;
}

.owl-testimonial-content::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 0;
  bottom: -19px;
  border-color: #fff transparent transparent #fff;
  border-width: 14px;
  border-style: solid;
}

.testimonial-quote {
  width: 200px;
  display: inline-block;
  padding-top: 30px;
}

.testimonial-quote span {
  display: block;
  font-size: 16px;
  color: #282828;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial-quote span:last-child {
  font-size: 13px;
  color: #b8b8b8;
  font-family: "Lora", serif;
}

.testimonial-customNavigation {
  text-align: right;
  position: relative;
  top: -68px;
  z-index: 10;
}

.testimonial-customNavigation span {
  padding: 0;
}

.testimonial-customNavigation span i {
  height: 51px;
  width: 51px;
  border: 2px solid #eee;
  line-height: 51px;
  text-align: center;
  outline: none;
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -ms-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
  background-color: #fff;
  color: #333333;
  font-size: 21px;
}

.testimonial-customNavigation span:hover i {
  color: #d5aa6d;
}

.t-b-comment {
  margin-top: 12px;
  min-height: 30px;
}

.t-b-comment span,
.t-b-comment span a {
  color: #474747;
  font-family: "Open Sans", sans-serif;
}

.t-b-comment span:first-child {
  float: left;
  font-size: 12px;
}

.t-b-comment span:last-child {
  float: right;
  font-size: 12px;
  margin-right: 0;
}

.t-b-comment .grey-text {
  color: #b8b8b8;
}

.t-b-details > h2 {
  font-size: 17px;
  font-family: "Lora", serif;
  font-weight: bold;
  position: relative;
  line-height: 25px;
  margin-top: 20px;
}

.t-b-details h2 a {
  color: #282828;
}

.t-b-details > h2:before {
  width: 30px;
  background-color: #474747;
  height: 3px;
  position: absolute;
  left: 0;
  top: -18px;
  content: "";
}

.t-b-details > h2 a:hover {
  color: #d5aa6d;
  -webkit-transition: all.5s;
  -o-transition: all.5s;
  transition: all.5s;
}

.t-b-details p {
  color: #9b9b9b;
  font-size: 13px;
  line-height: 24px;
}

.client-customNavigation {
  text-align: center;
  position: relative;
  top: 35px;
}

/* .client-carousel .client-item {
  border-right: 1px #e5e4e4 solid;
} */

.client-item:after {
  background: #e5e4e4;
  bottom: 0;
  content: "";
  height: 70px;
  margin: auto;
  position: absolute;
  right: -2px;
  top: 0;
  width: 1px;
}

@media screen and (max-width: 576px) {
  .progressbar {
    padding: 0 20px;
  }
}
