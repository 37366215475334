.inner-single-practice > h2 {
    font-size: 18px;
    color: #222222;
    font-weight: bold;
    margin-bottom: 20px;
}

.inner-single-practice > p {
    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    color: #9b9b9b;
    margin-bottom: 35px;
}

.d-brochures li a {
    display: block;
    border: 2px solid #eee;
    padding: 13px 13px 13px 60px;
    text-align: center;
    position: relative;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 30px;
    color: #333333;
    -webkit-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
}

.d-brochures li a i {
    position: absolute;
    bottom: -7px;
    left: 13px;
    background-color: #d5aa6d;
    color: #fff;
    font-size: 24px;
    height: 41px;
    width: 41px;
    line-height: 41px;
    text-align: center;
    -webkit-border-radius: 2px 2px 2px 0;
    -khtml-border-radius: 2px 2px 2px 0;
    -moz-border-radius: 2px 2px 2px 0;
    -ms-border-radius: 2px 2px 2px 0;
    -o-border-radius: 2px 2px 2px 0;
    border-radius: 2px 2px 2px 0;
}

.d-brochures li a i:after {
    height: 0;
    width: 0;
    left: 0;
    bottom: -7px;
    position: absolute;
    border-style: solid;
    border-width: 4px;
    border-color: #d5aa6d transparent transparent #d5aa6d;
    content: "";
}