.view-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.1rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.view-container-doc {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.1rem;
  justify-content: flex-start;
  align-items: flex-start;
}
.attach-document-in-right-side {
  color: black;
  text-decoration: none;
  padding: 0.6rem 0.3rem;
  display: flex;
  flex-direction: row;
  background: #c4c6c6b6;
  min-width: 100%;
  max-width: 100%;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}
.item-container-attach-doc {
  width: 100%;
}
.item-container-attach {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;
}
.item-size {
  width: 100%;
  height: 100%;
}
/* 
@media screen and (max-width: 992) {
    
} */
