.blog-down-tags {
  min-width: fit-content;
}

.badge-for-comment-user-in-blog {
  background-color: #6c6054;
  font-size: 10px;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  padding: 2.5px 4px;
}

.div-of-react-quill > p > img,
.ql-editor > p > img {
  max-width: 100%;
}

.blog-tb-details > p {
  font-size: 15px;
  padding-bottom: 20px;
  font-family: "Raleway", sans-serif;
}

.blog-social {
  margin-top: 50px;
}

.blog-social a {
  font-size: 14px;
  color: #333333;
  display: inline-block;
  margin-left: 20px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.blog-social a:hover {
  color: #d5aa6d;
}

.blog-prev-next {
  font-family: "Lora", serif;
  margin-bottom: 50px;
}

.blog-next-content,
.blog-prev-content {
  border: 2px solid #eee;
  padding: 20px;
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.blog-prev-next p {
  font-style: italic;
  font-family: "Lora", serif;
  margin: 0;
  line-height: 1;
  padding-bottom: 4px;
}

.blog-prev-next h3 {
  margin: 0;
  font-size: 17px;
  font-weight: bold;
  line-height: 23px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.prev-left a,
.next-right a {
  position: relative;
}

.blog-prev a i,
.blog-next a i {
  height: 41px;
  width: 41px;
  line-height: 41px;
  text-align: center;
  background-color: #1b1a1a;
  color: #fff;
  font-size: 21px;
  float: left;
  border-radius: 2px 2px 2px 0;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.prev-left a::after,
.next-right a::after {
  content: "";
  height: 0;
  border-width: 4px;
  position: absolute;
  width: 0;
  border-style: solid;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.prev-left a::after {
  border-color: #1b1a1a transparent transparent #1b1a1a;
  left: -41px;
  bottom: -48px;
}

.next-right a::after {
  border-color: #1b1a1a #1b1a1a transparent transparent;
  right: 0;
  bottom: -47px;
}

.blog-next-content:hover,
.blog-prev-content:hover {
  border: 2px solid #d5aa6d;
}

.blog-next-content:hover h3,
.blog-prev-content:hover h3 {
  color: #d5aa6d;
}

.blog-next-content:hover .next-right a i,
.blog-prev-content:hover .prev-left a i {
  background: #d5aa6d;
}

.blog-next-content:hover .next-right a::after {
  border-color: #d5aa6d #d5aa6d transparent transparent;
}
.blog-prev-content:hover .prev-left a::after {
  border-color: #d5aa6d transparent transparent #d5aa6d;
}

.single-blog-author-post p {
  font-size: 15px;
  color: #9b9b9b;
  font-family: "Raleway", sans-serif;
  border-bottom: none;
}

.blog-single-img {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.b-p-i-overlay {
  background-image: linear-gradient(transparent, #282828);
  bottom: 0;
  height: 50%;
  left: 0;
  position: absolute;
  width: 100%;
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -ms-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
  opacity: 0;
}

.blog-single-img p {
  font-size: 17px;
  line-height: 26px;
  font-weight: normal;
  margin: 0;
  padding: 14px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-transform: translateY(60%);
  -khtml-transform: translateY(60%);
  -moz-transform: translateY(60%);
  -ms-transform: translateY(60%);
  -o-transform: translateY(60%);
  transform: translateY(60%);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -ms-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}

.blog-single-img:hover p {
  -webkit-transform: translateY(0%);
  -khtml-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
  filter: alpha(opacity=1);
}

.blog-single-img p a {
  font-size: 17px;
  color: #ffffff;
  font-family: "Lora", serif;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: bold;
}

.blog-single-img p:hover a,
.blog-reply-content h3:hover a {
  color: #d5aa6d;
}

.blog-single-img:hover .b-p-i-overlay {
  opacity: 1;
}

.blog-reply-content {
  padding-bottom: 30px;
  border-bottom: 2px solid #eee;
}

.blog-reply-content h3 a {
  color: #222222;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.blog-reply-content h4 {
  font-size: 12px;
  color: #b8b8b8;
  font-family: "Open Sans", sans-serif;
}

.blog-reply-content > a {
  font-size: 13px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  background: #333333;
  display: inline-block;
  padding: 3px 13px 6px;
  text-decoration: none;
  border-radius: 3px;
  font-weight: bold;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.blog-reply-content > a:hover {
  color: #fff;
  background: #d5aa6d;
}

.blog-reply-form form {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

.blog-reply-form form .form-control {
  font-size: 13px;
  box-shadow: none;
  border: 1px solid #ccc;
}

.blog-reply-form form input,
.blog-reply-form form textarea {
  outline: 0;
  border-radius: 0px;
  border: 1px solid #ccc;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.blog-reply-form form input,
.blog-reply-form form input:focus {
  height: 35px;
  margin-bottom: 20px;
}

.blog-reply-form form textarea,
.blog-reply-form form textarea:focus {
  resize: none;
}
.div-of-react-quill > p > img {
  width: 50% !important;
}

@media screen and (max-width: 576px) {
  .blog-prev-next h3 {
    font-size: 15px;
  }
  .blog-social {
    margin-top: 20px;
  }
}
.blog-prev.disabled {
  opacity: 0.5; /* Reduced opacity for disabled state */
  pointer-events: none; /* Disable pointer events on the element */
}

.blog-prev-content.disabled {
  cursor: default; /* Change cursor to default for disabled state */
  color: gray; /* Change text color to gray for disabled state */
}

.blog-prev-content.disabled .prev-left .fa {
  color: gray; /* Change arrow color to gray for disabled state */
}

.blog-next.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.blog-next-content.disabled {
  cursor: default;
  color: gray;
}

.blog-next-content.disabled .next-right .fa {
  color: gray;
}
