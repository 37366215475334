.view-carousel {
  width: 90%;
}

.one-row-of-carousel-setting {
  border: 1px solid orange;
  border-radius: 15px;
  padding: 1.2rem 1rem;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.image-of-carousel-in-table {
  width: 100px;
}

.text-container-in-carousel {
  position: absolute;
  width: 70%;
  bottom: 10%;
  left: 50%; /* Position the left edge at the horizontal center */
  transform: translateX(-50%); /* Center horizontally */
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.carousel-container-in-carousel {
  width: 90% !important;
  margin: 10px auto;
}

.text-line-1 {
  font-size: 22px;
  font-weight: 500;
  margin: 5px 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
}
.text-line-2 {
  font-size: 28px;
  font-weight: 700;
  margin: 5px 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
  .text-line-1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  .text-line-2 {
    font-size: 26px;
    font-weight: 700;
    margin: 0;
  }
}
@media screen and (max-width: 756px) {
  .text-line-1 {
    font-size: 18px;
    font-weight: 400;
  }
  .text-line-2 {
    font-size: 22px;
    font-weight: 550;
  }
  .text-carousel-table {
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  .text-line-1 {
    font-size: 10px;
  }
  .text-line-2 {
    font-size: 13px;
  }
  .text-container-in-carousel {
    width: 75%;
  }
  .text-carousel-table {
    font-size: 10px;
  }
}
