.chat-icon-container {
  position: fixed;
  bottom: 40px;
  right: 50px;
  z-index: 9999999999;
}

.chat-icon-button {
  background-color: #eaa954;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  position: relative;
}

.unread-count {
  background-color: rgb(233, 25, 64);
  color: #fff;
  width: 20px;
  height: 20px;
  text-decoration: none !important;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.chat-icon-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  display: block;
}

.dashboard-body {
  background-color: #f2f3fb;
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-template-columns: auto 79%;
}

.aside-toggler {
  z-index: 12;
}

.dashboard-aside .aside-toggler {
  right: 15px;
}

.aside-container {
  background-color: #f2f3fb;
  z-index: 20;
}
.mantine-Group-root .mantine-Pagination-control[data-active] {
  background-color: #eaa954 !important;
}

.dashboard-body .dashboard-aside {
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 8;
}

.aside-overlay {
  z-index: 14;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
}

.dashboard-body .dashboard-aside::-webkit-scrollbar,
.dashboard-body .outlet::-webkit-scrollbar,
.case-details-container .case-details-body::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}

.dashboard-body .dashboard-aside::-webkit-scrollbar-thumb,
.dashboard-body .outlet::-webkit-scrollbar-thumb,
.case-details-container .case-details-body::-webkit-scrollbar-thumb {
  background: #ced2db;
  border-radius: 20px;
}

.dashboard-body .dashboard-aside .aside-user-profile-pic img {
  width: 134px;
  height: 134px;
  object-fit: cover;
}

.dashboard-body .dashboard-label {
  background-color: #5159771a;
  display: flex;
  max-width: 200px;
  gap: 15px;
  padding: 14px;
  align-items: center;
  color: #515977;
}

.dashboard-aside .admin-aside-navigation {
  overflow-x: auto;
  height: 100%;
  max-height: calc(100vh - 412px);
}

@media screen and (max-width: 1400px) {
  .dashboard-aside .admin-aside-navigation {
    max-height: calc(100vh - 420px);
  }
}

.dashboard-body .details h2 {
  font-size: 1.65rem;
  line-height: 0.5;
}

.dashboard-aside .details h2 {
  line-height: 1;
  text-align: center;
}

.degree-heading,
.degree {
  font-family: var(--poppins);
  font-size: 12px;
  font-weight: 500;
}

.degree-heading {
  color: #5d5d5d;
}

.degree {
  color: #db9753;
}

.dashboard-body .ratings {
  background-color: unset;
}

.ratings .total-reviews {
  color: #5e5e5e;
}

.dashboard-body .consultation-fee {
  background: #06152b;
  border: 1px solid #f0f0f0;
  box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 9px;
}

.dashboard-body .consultation-fee .response-duration {
  color: #43bc79;
  font-weight: 600;
}

.dashboard-body .content-white-container {
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.dashboard-body .outlet {
  overflow: auto;
  height: 100%;
  padding: 0 0 50px;
}

.dashboard-body .search-bar {
  z-index: 10;
  border: 1px solid #f0f0f0;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.07);
  margin-bottom: 0;
}

.dashboard-body .activity-select-dropdown.mantine-Select-root input {
  font-weight: 600;
  font-family: var(--poppins);
  width: 145px;
  border-color: #515977;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.dashboard-body .mantine-RingProgress-label {
  text-align: center;
  color: white;
  font-weight: 700;
}

.statics {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.statics > div {
  min-width: 230px;
}

.statics .mantine-Group-root {
  justify-content: space-between;
}

.purple-stat .mantine-RingProgress-root svg circle {
  stroke: #393781;
}

.yellow-stat .mantine-RingProgress-root svg circle {
  stroke: #8b7436;
}

.brown-stat .mantine-RingProgress-root svg circle {
  stroke: #75382d;
}

.purple-stat .mantine-RingProgress-root svg circle + circle,
.yellow-stat .mantine-RingProgress-root svg circle + circle,
.brown-stat .mantine-RingProgress-root svg circle + circle {
  stroke: white;
}

.dashboard-body .clients-container-header {
  z-index: 2;
  position: sticky;
  top: 0;
  border-radius: 22px 22px 0 0;
  background-color: #f4eacf;
  -webkit-border-radius: 22px 22px 0 0;
  -moz-border-radius: 22px 22px 0 0;
  -ms-border-radius: 22px 22px 0 0;
  -o-border-radius: 22px 22px 0 0;
}

.dashboard-body .-header span {
  font-size: 1.25rem;
  font-weight: 500;
}

.dashboard-body .case-type-dropdown.mantine-Select-root input {
  font-family: var(--poppins);
  font-weight: 500;
  font-size: 16px !important;
  width: 210px;
  background-color: #c4b07a !important;
  color: white;
  border: none;
  outline: none;
  border-radius: 7px !important;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.dashboard-body .case-type-dropdown.mantine-Select-root i {
  color: white;
}

.dashboard-body .clients-container {
  overflow: hidden;
  height: 100%;
  border: 1px solid #dcd6d6;
  border-radius: 22px 22px 0 0;
  -webkit-border-radius: 22px 22px 0 0;
  -moz-border-radius: 22px 22px 0 0;
  -ms-border-radius: 22px 22px 0 0;
  -o-border-radius: 22px 22px 0 0;
}

.clients::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}

.clients::-webkit-scrollbar-thumb {
  background: #ced2db;
  border-radius: 20px;
}

.clients-container .clients {
  overflow: auto;
  min-height: 220px;
  max-height: calc(100vh - 400px);
}

.dashboard-navigation .navigation {
  margin-inline-start: 25px;
}

.sidebar-name {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.dashboard-body .clients .client .client-name {
  font-size: 20px;
  font-weight: 500;
}

.dashboard-body .clients .client .case-description-btn i {
  font-size: 11px;
  margin-left: 3px;
}

span.green-color-status {
  background-color: #e6f9f3;
  color: #44ba91;
  width: 100%;
  padding: 5px 25px;
}

span.brown-color-status {
  background-color: #ebd9c8;
  color: #ea8934;
  width: 100%;
  padding: 5px 25px;
}

.activity-select-dropdown.green-color-status input {
  border: 0.5px solid #44ba91 !important;
  background-color: #e6f9f3;
  color: #44ba91;
}

.activity-select-dropdown.red-color-status input {
  border: 0.5px solid #c0313c !important;
  background-color: #fef2f2;
  color: #c0313c;
}

.dashboard-body .clients .client::before {
  content: " ";
  height: 100%;
  width: 16px;
  border-radius: 18px 0 0 18px;
  background-color: #d5aa6d;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
}

.dashboard-body .clients .client:hover::before {
  opacity: 1;
}

.dashboard-body .clients .client {
  height: fit-content;
  border: 1px solid #d5aa6d;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
}

.yellow-color-status input {
  border: 0.5px solid #e6ab57 !important;
  background-color: #fef5ee;
  color: #e6ab57;
}

.grey-label {
  color: #828282;
}

.case-details-container {
  border: 1px solid #dfd5d5;
  box-shadow: 0px 4px 9px 2px rgba(0, 0, 0, 0.08);
  border-radius: 11px;
  padding: 0 !important;
}

.case-details-container .case-details-header .fa-chevron-up {
  color: #abb2ba;
}

.case-details-container .case-details-header .subject-heading {
  color: #828282;
}

.case-details-header .gear-dropdown {
  border: 0.9px solid #ebebeb;
  border-radius: 4.5px;
}

.case-details-header .gear-dropdown a {
  color: #828282;
  font-size: 12px;
}

.case-details-header .gear-dropdown .dropdown-divider {
  opacity: 0.3;
  margin: 4px 0;
}

.case-details-container .case-details-body {
  overflow-y: auto;
  max-height: 400px;
}

.case-details-container .case-details-body p {
  font-family: var(--poppins);
  font-size: 14px;
  margin-bottom: 25px;
}

.mantine-Group-root .mantine-Pagination-control:first-child,
.mantine-Group-root .mantine-Pagination-control:last-child {
  border: none;
}

.mantine-Group-root .mantine-Pagination-control[data-active] {
  background: #515977;
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.14);
}

.mantine-Group-root .mantine-Pagination-control[data-active]:hover {
  background: #3b4156;
}

.mantine-Input-icon.mantine-DatePickerInput-icon {
  right: 0;
  left: unset;
}

.mantine-DatePickerInput-input.mantine-Input-input {
  padding-right: 2.25rem;
  padding-left: calc(2.25rem / 3);
}

.mantine-RingProgress-root svg {
  transform: rotate(90deg) !important;
  -webkit-transform: rotate(90deg) !important;
  -moz-transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important;
  -o-transform: rotate(90deg) !important;
}

.clients .client .mantine-DatePickerInput-root,
.clients .client .mantine-TimeInput-root {
  margin: 0;
}

.account-settings-aside .aside-logo::after {
  content: " ";
  position: absolute;
  width: 100% !important;
  left: 0;
  top: 180px;
  height: 1px;
  background-color: #b5b5b5;
}

.account-settings-aside .aside-menu-list h2 {
  color: #dd995f;
  margin-top: 25px;
  margin-bottom: 15px;
  font-family: var(--montserrat);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.1rem;
}

.account-settings-aside .aside-menu-list li {
  font-family: var(--poppins);
  font-weight: 400;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: pointer;
}

.account-settings-aside .aside-menu-list li a {
  text-decoration: none;
  color: #888f98;
  margin-bottom: 25px;
  transition: 0.5s ease-in-out;
}

.account-settings-aside .aside-menu-list li:hover a {
  color: #db9753;
}

.csdssasd {
  font-size: 1.2rem !important;
}

.aside-menu-list {
  max-height: 50vh;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.aside-menu-list::-webkit-scrollbar,
.dashboard-aside .admin-aside-navigation::-webkit-scrollbar {
  width: 6px;
}

.aside-menu-list::-webkit-scrollbar-track,
.dashboard-aside .admin-aside-navigation::-webkit-scrollbar-track {
  background-color: #d6d6d6;
}

.aside-menu-list::-webkit-scrollbar-thumb,
.dashboard-aside .admin-aside-navigation::-webkit-scrollbar-thumb {
  background-color: #db9753;
}

.row-in-dashboard-page-of-superadmin {
  padding: 1rem;
  max-width: 100%;
}

.first-div-of-dashboard-page-of-superadmin {
  padding: 2rem;
}

.lawyer-availability-table {
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
}

@media screen and (max-width: 1200px) {
  .dashboard-body {
    grid-template-columns: 100%;
  }
  .dashboard-body .dashboard-aside {
    width: 40vw;
  }
  .aside-container {
    top: 0;
    position: fixed;
    transition: ease-out 0.6s;
    transform: translateX(-600px);
    -webkit-transform: translateX(-600px);
    -moz-transform: translateX(-600px);
    -ms-transform: translateX(-600px);
    -o-transform: translateX(-600px);
    -webkit-transition: ease-out 1s;
    -moz-transition: ease-out 1s;
    -ms-transition: ease-out 1s;
    -o-transition: ease-out 1s;
  }
  .aside-container.active {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  .dashboard-body .case-type-dropdown.mantine-Select-root input {
    font-size: 14px;
    width: 150px;
    height: 25px;
  }
}

@media screen and (max-width: 992px) {
  .dashboard-body .clients .client::before {
    display: none;
  }
  .first-div-of-dashboard-page-of-superadmin {
    padding: 1.4rem 2rem;
  }

  .dashboard-body .search-bar {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 756px) {
  .aside-menu-list {
    max-height: 50vh !important;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 767px) {
  .dashboard-body .dashboard-aside {
    width: 50vw !important;
  }
  .first-div-of-dashboard-page-of-superadmin {
    padding: 1rem 1.6rem;
  }
}

@media screen and (max-width: 576px) {
  .appointment-detail {
    font-size: 14px;
  }
  .dashboard-body {
    height: auto;
    max-height: unset;
  }

  .row-in-dashboard-page-of-superadmin {
    padding: 0.8rem 0.4rem;
  }
  .chat-icon-container {
    right: 35px;
  }
  .react-apex-container-in-dashboard {
    min-width: 575px;
  }
  .first-div-of-dashboard-page-of-superadmin {
    padding: 0.4rem 1rem;
  }

  .dashboard-body .dashboard-aside .aside-user-profile-pic img {
    width: 140px;
  }
  .dashboard-body .dashboard-aside {
    width: 100vw !important;
  }
  .dashboard-body .dashboard-label {
    gap: 12px;
    padding: 10px;
  }
  .statics > div {
    width: 173px;
  }
  .statics .mantine-Text-root:first-child {
    font-size: 14px;
  }
  .statics .mantine-Text-root:last-child {
    font-size: 12px;
  }
  .statics .mantine-RingProgress-label {
    font-size: 12px;
  }
}

@media screen and (min-width: 1201px) {
  .xl-screen-none {
    display: none !important;
  }
}
@media screen and (min-width: 1201px) {
  .hi-there-it-is-necessary {
    padding-left: 1.5rem !important;
  }
}
