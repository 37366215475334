@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

nav.position-sticky {
  z-index: 100;
}

.brand-logo {
  width: 80px;
}

.navigation a.nav-link {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.navigation a.active:before {
  position: absolute;
  background-color: var(--dark-bg-color);
  content: "";
  height: 3px;
  margin-top: -3px;
  width: 10px;
  opacity: 1;
  filter: alpha(opacity=1);
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -ms-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}

.navigation a.active {
  color: var(--dark-bg-color) !important;
  font-weight: 800;
}

.navigation a::before {
  position: absolute;
  background-color: var(--dark-bg-color);
  content: "";
  height: 3px;
  margin-top: -2px;
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -ms-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
  width: 0px;
}

.navigation a:hover {
  color: var(--dark-bg-color) !important;
}

.navigation a:hover::before {
  position: absolute;
  background-color: var(--dark-bg-color);
  content: "";
  height: 3px;
  margin-top: -2px;
  width: 10px;
  opacity: 1;
  filter: alpha(opacity=1);
  -webkit-transition: all 300ms linear 0ms;
  -moz-transition: all 300ms linear 0ms;
  -ms-transition: all 300ms linear 0ms;
  transition: all 300ms linear 0ms;
}

.navigation a {
  color: black;
  font-weight: 500;
}

.navigation a:hover {
  color: #db9753;
}

.header .navbar-toggler {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: unset;
  border: none;
  box-shadow: none !important;
}

.user-settings-btn .dropdown-menu {
  margin-left: -113px !important;
  right: -15px;
  /* max-width: 200px; */
}

.dropdown-icon {
  width: 36px;
  position: absolute;
  margin-right: 1px;
  right: 0;
  top: 0;
  margin-top: -24px;
  z-index: 1000;
}

.dropdown-container .username {
  font-size: 1.25rem;
  font-weight: 500;
  color: #5d5d5d;
  line-height: 1;
}

.dropdown-container .email {
  font-size: 0.75rem;
  font-weight: 400;
  color: #5d5d5d;
}

.dropdown-container .dropdown-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.dropdown-toggle::after {
  display: inline-block;
  content: "\f078";
  color: #d79135;
  font-size: 1.25rem;
  font-family: "fontAwesome";
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
/* .dropdown-menu {
  width: 250px;
} */

.navbar-expand-lg .navbar-nav .dropdown-menu {
  right: 0;
  border-radius: 0;
}

.dropdown-menu[data-bs-popper] {
  top: unset;
  left: unset;
  margin-top: unset;
}

.loginbtn {
  border: none;
  background: linear-gradient(180deg, #dd995f 0%, #d28b13 100%);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.24);
  color: white;
}

.joinbtn {
  background-color: transparent;
  color: var(--primary-color);
  font-family: var(--poppins);
  font-weight: 500;
  border: 1px solid var(--border-color);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.24);
}

.authbtns {
  border-radius: 3px;
  padding: 7px 42px;
  transition: 0.4s ease-in-out;
}

.authbtns:hover {
  transform: translateY(-2px);
  box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.24);
}

@media screen and (max-width: 756px) {
  .brand-logo {
    width: 65px;
  }
}

@media screen and (max-width: 540px) {
  .user-settings-btn .dropdown-menu {
    margin-left: -80px !important;
    right: 0;
  }
  .brand-logo {
    width: 50px;
  }
}

.user-settings-btn .dropdown-item:active {
  background-color: #d79135;
}

.user-settings-btn .dropdown-item:active svg path {
  fill: white;
}

.dashboard-header a:hover {
  color: #d28b13 !important;
}

.dashboard-header a.active {
  color: #d28b13 !important;
}

@media screen and (min-width: 992px) {
  .navbar-brand {
    width: 10% !important;
  }
}

@media screen and (max-width: 1200px) {
  .navigation a.nav-link {
    font-size: 14px;
  }
  .authbtns {
    font-size: 14px;
  }
}
@media screen and (max-width: 992px) {
  .navLinks-width {
    display: none;
  }
}
